
export default {
    app_info: {
      app_name: "buy_again_repeat_orders",
      app_id: "5d01fc53-347b-46ec-ac2f-bc6b96a06299",
      app_title: "Buy Again Repeat Orders",
      app_icon:
        "https://static.wixstatic.com/media/0904c3_dab96ef47e4c4ff4957da54841575dd9~mv2.png",
    },
  
    main_links: {

      faq_url:
        "https://help.marketpushapps.com/en/articles/10411550-how-to-use-buy-again-repeat-orders",
      review_url:
        "https://www.wix.com/app-market/add-review/5d01fc53-347b-46ec-ac2f-bc6b96a06299",
    },
  
    storage: {
      collection_name: "buy_again_repeat_orders ",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "The Buy Again & Repeat Orders app makes reordering easy. With a simple login, you can instantly view your complete order history, including product images and details. Reorder an entire past order with one click or select individual items to buy again. No more searching for previous purchases – just view your history, click to reorder, and you're done! It's the perfect solution for quickly restocking your regular items without the hassle.",

      url: "https://help.marketpushapps.com/en/articles/10411550-how-to-use-buy-again-repeat-orders",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Buy Again Repeat Orders",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  