
export default {
    app_info: {
        app_name: "social_media_share_follow",
        app_id: "5bbbd8a0-ff63-4307-97b3-20116f07767e",
        app_title: "Social Media Share & Follow",
        app_icon: "https://static.wixstatic.com/media/33caa7_485b78477bf84e168aa1afb5389cfed9~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/7337582-social-media-share-follow", 
        review_url: "https://www.wix.com/app-market/add-review/5bbbd8a0-ff63-4307-97b3-20116f07767e",
    },
    storage: {
        collection_name: "social_media_share_follow",
      },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Social Media Share & Follow app lets you easily integrate social media widgets on your website, offering Follow and Share options. The Follow widget enables visitors to connect with your brand on platforms like WhatsApp, Facebook, Instagram, and more, building your online presence. The Share widget allows visitors to share your content with their networks, amplifying your reach and driving traffic. Choose from 20+ social networks and 90+ icons to match your site’s style, enhancing engagement and promoting organic growth.",
        url: "https://help.marketpushapps.com/en/articles/8713907-how-to-add-social-follow-buttons-on-your-site",
    },
    

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to use Facebook Reviews App?",
                    url: "https://help.marketpushapps.com/en/articles/8702040-how-to-use-facebook-reviews-app",
                },
                {
                    title: "2. How to change widget position?",
                    url: "https://help.marketpushapps.com/en/articles/8713861-how-to-change-widget-position",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Social Media Share & Follow",
            thumbnail: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/social%20media_yt_thumbnail.png", 
            url: "https://www.youtube.com/watch?v=8xbL8oERHBc",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Social Media Share & Follow",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};