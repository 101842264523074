export default {
    app_info: {
        app_name: "addblocker_detector",
        app_id: "87e345ec-e79a-4b01-bee3-c6610b1b0c20",
        app_title: "Adblocker Detector",
        app_icon: "https://static.wixstatic.com/media/19e924_dabbb239a60243d69ff2f0ce9256bf2e~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/7337565-adblocker-detector", 
        review_url: "https://www.wix.com/app-market/add-review/87e345ec-e79a-4b01-bee3-c6610b1b0c20",
    },
    storage: {
        collection_name: "addblocker_detector",
      },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Adblocker Detector helps site owners protect ad revenue by detecting ad-blocker usage and displaying customizable popups to address it. Tailor warning messages to match your brand’s look using ready-to-use templates or detailed customization. Prompt visitors to disable their ad-blocker or proceed with a non-intrusive request, ensuring a balance between user experience and revenue preservation. This app enhances user engagement while safeguarding your site's ad revenue, making it a valuable tool for managing ad-blocker challenges and maximizing earnings.",
        url: "https://help.marketpushapps.com/en/articles/8729543-how-to-use-adblocker-detector",
    },
    

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Help Articles",
            articles: [
                {
                    title: "1. The Popup is not showing on homepage",
                    url: "https://help.marketpushapps.com/en/articles/8863548-the-popup-is-not-showing-on-homepage",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Social Media Share & Follow",
            thumbnail: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/social%20media_yt_thumbnail.png", 
            url: "https://www.youtube.com/watch?v=8xbL8oERHBc",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Adblocker Detector",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};