//

export default {
    app_info: {
        app_name: "affiliate_builder_pro",
        app_id: "e3929932-1824-40f2-9288-8ad385c5a7dc",
        app_title: "Affiliate Builder",
        app_icon: "https://static.wixstatic.com/media/19e924_3f0fd6c4bd6e408baaa5acc96b28e9d2~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_3f0fd6c4bd6e408baaa5acc96b28e9d2~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/3952700-affiliate-builder", 
        review_url: "https://www.wix.com/app-market/add-review/e3929932-1824-40f2-9288-8ad385c5a7dc",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Easily create and automate your Affiliate Program in minutes, empowering your Site Members to promote your brand and earn commissions. Seamlessly integrate the platform into your site's logged-in area, eliminating the need for external registration. Affiliates can share unique links on social media, track clicks and payouts via a personalized dashboard, and earn commissions on sales. Boost traffic and expand reach with a powerful program that supports stores, bookings, events, restaurants, and recurring commissions. Effortlessly manage and grow your sales with valuable insights and data-driven decisions.",
        url: "https://help.marketpushapps.com/en/articles/8701399-how-to-setup-affiliate-builder-app",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to setup Affiliate Builder App?",
                    url: "https://help.marketpushapps.com/en/articles/8701399-how-to-setup-affiliate-builder-app",
                },
                {
                    title: "2. Enable Site Members area on your site",
                    url: "https://help.marketpushapps.com/en/articles/8011559-enable-site-members-area-on-your-site",
                },
                {
                    title: "3. Restrict the Affiliate Program to a subset of users",
                    url: "https://help.marketpushapps.com/en/articles/8012685-restrict-the-affiliate-program-to-a-subset-of-users",
                },
                {
                    title: "4. How to show Affiliate links for Homepage or Current Page?",
                    url: "https://help.marketpushapps.com/en/articles/9079182-how-to-show-affiliate-links-for-homepage-or-current-page",
                },
                {
                    title: "5. How To invite Non-Logged-In visitors to your Affiliate Platform?",
                    url: "https://help.marketpushapps.com/en/articles/9079202-how-to-invite-non-logged-in-visitors-to-your-affiliate-platform",
                },
                {
                    title: "6. How to optimise for mobile?",
                    url: "https://help.marketpushapps.com/en/articles/9079213-how-to-optimise-for-mobile",
                },
            ],
        },
        {
            collection_name: "Help Articles",
            articles: [
                {
                    title: "Affiliate Widgets don't display on my site",
                    url: "https://help.marketpushapps.com/en/articles/7257271-affiliate-widgets-don-t-display-on-my-site",
                },
                {
                    title: "Can Affiliates from another system be imported within the Affiliate Builder app?",
                    url: "https://help.marketpushapps.com/en/articles/9151344-can-affiliates-from-another-system-be-imported-within-the-affiliate-builder-app",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Affiliate Builder",
            thumbnail: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/Affiliate_Wix%20Stores_yt_thumbnail.png", 
            url: "https://www.youtube.com/watch?v=NwgKiL6OdlU",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Affiliate Builder",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};
