export default {
    app_info: {
        app_name: "sms_blast",
        app_id: "644c2734-8f81-4bc1-b735-86945f629193",
        app_title: "SMS Blast",
        app_icon: "https://static.wixstatic.com/media/19e924_8d2258e672ca4f04bc6c80dd570b5525~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/3856947-sms-blast", 
        review_url: "https://www.wix.com/app-market/add-review/644c2734-8f81-4bc1-b735-86945f629193",
    },
    storage: {
        collection_name: "sms_blast",
      },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "SMS Blast is a powerful app for both marketing and alerts, helping you stay connected with customers. You can send bulk SMS marketing campaigns to inform customers about important events, using phone number lists imported from Contacts or external sources. SMS has a higher read rate than email, making it effective for mass announcements. Additionally, receive SMS alerts for key events, such as new orders, bookings, form submissions, and restaurant orders. Send up to 2 SMS reminders for upcoming bookings to ensure customers never miss an appointment.",
        url: "https://help.marketpushapps.com/en/articles/8695589-how-to-setup-booking-reminders",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to setup Booking Reminders?",
                    url: "https://help.marketpushapps.com/en/articles/8695589-how-to-setup-booking-reminders",
                },
                {
                    title: "2. How to import your Contacts",
                    url: "https://help.marketpushapps.com/en/articles/6988220-how-to-import-your-contacts",
                },
                {
                    title: "3. How can customers opt-in",
                    url: "https://help.marketpushapps.com/en/articles/6988682-how-can-customers-opt-in",
                },
                {
                    title: "4. How can customers opt-out",
                    url: "https://help.marketpushapps.com/en/articles/6988702-how-can-customers-opt-out",
                },
                {
                    title: "5. How to send an SMS to visitors that submit a form",
                    url: "https://help.marketpushapps.com/en/articles/7222718-how-to-send-an-sms-to-visitors-that-submit-a-form",
                },
            ],
        },
        {
            collection_name: "Help Articles",
            articles: [
                {
                    title: "Can I use my phone number for SMS sending",
                    url: "https://help.marketpushapps.com/en/articles/6988579-can-i-use-my-phone-number-for-sms-sending",
                },

                {
                    title: "Why can't I select all of my contacts?",
                    url: "https://help.marketpushapps.com/en/articles/8690764-why-can-t-i-select-all-of-my-contacts",
                },
                {
                    title: "How long does the review process take",
                    url: "https://help.marketpushapps.com/en/articles/6988659-how-long-does-the-review-process-take",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Facebook Reviews",
            thumbnail: "https://img.youtube.com/vi/aAC91j4hnEs/maxresdefault.jpg", 
            url: "https://www.youtube.com/watch?v=aAC91j4hnEs",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "SMS Blast",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};