//

export default {
    app_info: {
        app_name: "booking_com_reviews",
        app_id: "3f340304-6c59-42d0-9687-293f6c30bd52",
        app_title: "Booking.com Reviews",
        app_icon: "https://static.wixstatic.com/media/19e924_d12a09d64bc3426a95969a02952a3322~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_d12a09d64bc3426a95969a02952a3322~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/7329935-booking-com-reviews", 
        review_url: "https://www.wix.com/app-market/add-review/3f340304-6c59-42d0-9687-293f6c30bd52",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Easily import and display Booking.com reviews on your website to enhance credibility and attract more bookings. With customizable review widgets, you can design visually appealing displays that match your website’s style. Automatically sync guest reviews, saving time and ensuring up-to-date feedback. Highlight positive testimonials to build trust, stand out from competitors, and increase bookings. Showcase authentic reviews to inspire confidence, maximize occupancy rates, and boost your property's online reputation, driving guest satisfaction and business growth.",
        url: "https://help.marketpushapps.com/en/articles/8702023-how-to-use-booking-com-reviews-app",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to use Booking.com Reviews App?",
                    url: "https://help.marketpushapps.com/en/articles/8702023-how-to-use-booking-com-reviews-app",
                },
                {
                    title: "2. How to change widget position?",
                    url: "https://help.marketpushapps.com/en/articles/8713857-how-to-change-widget-position",
                },
                {
                    title: "3. How to add a link to your Booking.com Listing within the widget?",
                    url: "https://help.marketpushapps.com/en/articles/8713870-how-to-add-a-link-to-your-booking-com-listing-within-the-widget",
                },
            ],
        },
        {
            collection_name: "Help Articles",
            articles: [
                {
                    title: "I don't have a Booking.com Page",
                    url: "https://help.marketpushapps.com/en/articles/8713142-i-don-t-have-a-booking-com-page",
                },

                {
                    title: "Is there a way to embed the widget so it doesn't scroll",
                    url: "https://help.marketpushapps.com/en/articles/8713274-is-there-a-way-to-embed-the-widget-so-it-doesn-t-scroll",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Booking.com Reviews",
            thumbnail: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/booking%20reviews_yt_thumbnail.png", 
            url: "https://www.youtube.com/watch?v=PD43OwfSpwg",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Booking.com Reviews",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};