export default {
    app_info: {
        app_name: "review_badges",
        app_id: "45678467-1daa-4e05-964a-71c255acfaf7",
        app_title: "Review Badges",
        app_icon: "https://static.wixstatic.com/media/0904c3_330bc40299664c91b281d1fc8337ba8a~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10130747-how-to-use-review-badges",
        review_url: "https://www.wix.com/app-market/add-review/45678467-1daa-4e05-964a-71c255acfaf7",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Seamlessly showcase customer reviews from 10 platforms, including Google, Facebook, and Yelp. Easy setup, 7 customizable templates, and real-time updates to boost your business's trust and credibility!",
        url: "https://help.marketpushapps.com/en/articles/10130747-how-to-use-review-badges",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Getting Started",
            // TBD
            articles: [
                {
                    title: "1. How to add the app & change the template?",
                    url: "https://help.marketpushapps.com/en/articles/10130747-how-to-use-review-badges#h_a863a25f52",
                },
                {
                    title: "2. What are the available templates?",
                    url: "https://help.marketpushapps.com/en/articles/10130747-how-to-use-review-badges#h_6cd334d305",
                },
                {
                    title: "3. Can my customers add a review?",
                    url: "https://help.marketpushapps.com/en/articles/10130747-how-to-use-review-badges#h_790db58c2a",
                },
                {
                    title: "4. How to modify the content?",
                    url: "https://help.marketpushapps.com/en/articles/10130747-how-to-use-review-badges#h_74a006b1e6",
                },
                {
                    title: "4. How to add/or remove items",
                    url: "https://help.marketpushapps.com/en/articles/10130747-how-to-use-review-badges#h_cd9e7a2422",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: false, // TBD
        videos: [{
            title: "How to use Review Badges",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=123456", // TBD
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Review Badges",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
            title: "App setup is too complicated",
            message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
            title: "Subscriptions are too expensive",
            message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
            title: "App has issues",
            message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
            title: "Other",
            message: "🥲 Feedback: I have a problem with --> ",
        },
        ],
    },
};