export default {
    app_info: {
        app_name: "expedia_reviews_widget",
        app_id: "2d74f818-77c8-4c97-af30-655eacf4169a",
        app_title: "Expedia Reviews Widget",
        app_icon: "https://static.wixstatic.com/media/19e924_460c6d90d1304edf9e730c1c772bc86b~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8713898-how-to-use-expedia-reviews", 
        review_url: "https://www.wix.com/app-market/add-review/2d74f818-77c8-4c97-af30-655eacf4169a",
    },
    storage: {
        collection_name: "expedia_reviews_widget",
      },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Expedia Reviews Widget allows you to easily showcase authentic guest reviews from your Expedia property on your website. Seamlessly connect your property and integrate reviews with a user-friendly interface. Choose from various design templates to match your site’s style, and highlight positive reviews to build trust and confidence with potential customers. By displaying Expedia reviews, you boost conversions and enhance your reputation, standing out in your industry with compelling social proof. Install the widget today and elevate your online presence.",
        url: "https://help.marketpushapps.com/en/articles/8713898-how-to-use-expedia-reviews",
    },
    

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to change widget position?",
                    url: "https://help.marketpushapps.com/en/articles/8713863-how-to-change-widget-position",
                },
                {
                    title: "2. How to use Expedia Reviews",
                    url: "https://help.marketpushapps.com/en/articles/8713898-how-to-use-expedia-reviews",
                },
                {
                    title: "3. How to add a link to your Expedia Listing within the widget",
                    url: "https://help.marketpushapps.com/en/articles/8713867-how-to-add-a-link-to-your-expedia-listing-within-the-widget",
                },
            ],
        },
        {
            collection_name: "Help Articles",
            articles: [
                {
                    title: "I don't have an Expedia Listing",
                    url: "https://help.marketpushapps.com/en/articles/8713163-i-don-t-have-an-expedia-listing",
                },

                {
                    title: "Is there a way to embed the widget so it doesn't scroll?",
                    url: "https://help.marketpushapps.com/en/articles/8713283-is-there-a-way-to-embed-the-widget-so-it-doesn-t-scroll",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Facebook Reviews",
            thumbnail: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/facebook%20reviews_yt_thumbnail.png", 
            url: "https://www.youtube.com/watch?v=fnroROvtRAA",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Expedia Reviews Widget",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};