
export default {
    app_info: {
      app_name: "tiktok_catalog_sync",
      app_id: "ebba2769-8aa9-4d8c-959d-a0446593012c",
      app_title: "Tiktok Catalog Sync",

      app_icon:
        "https://static.wixstatic.com/media/33caa7_11b22746a5e34633b407129363646ce5~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/collections/7444191-tiktok-catalog-sync",
      review_url:
        "https://www.wix.com/app-market/add-review/ebba2769-8aa9-4d8c-959d-a0446593012c",
    },
  
    storage: {
      collection_name: "tiktok_catalog_sync",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "TikTok Catalog Sync simplifies syncing your store’s products with TikTok Business Center, allowing you to effortlessly add your products for TikTok ads. The app automatically updates product changes, ensuring everything stays in sync without manual effort. While it doesn’t generate content, it provides a CSV feed to TikTok Business Center for seamless integration. Ideal for businesses looking to advertise on TikTok, this app helps turn your products into engaging ads, boosting visibility and driving e-commerce success on the platform.",
      url: "https://help.marketpushapps.com/en/articles/8713914-how-to-use-tiktok-catalog-sync-app",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: true,
      videos: [
        {
          title: "How to use Tiktok Catlog Sync",
          thumbnail: "https://img.youtube.com/vi/nTZ2y_PTbV0/maxresdefault.jpg",
          url: "https://www.youtube.com/watch?v=nTZ2y_PTbV0",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Tiktok Catlog Sync",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
 