export default {
    app_info: {
      app_name: "marketplace_store",
      app_id: "22616d9a-f3db-4bb4-bf13-5aa2a0f68d0b",
      app_title: "Marketplace Store",
      app_icon:
        "https://static.wixstatic.com/media/c7fe21_3b3e77cd90834d88898f0dbaad49e1a6~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/collections/7444600-multi-vendor-store",
      review_url:
        "https://www.wix.com/app-market/add-review/22616d9a-f3db-4bb4-bf13-5aa2a0f68d0b",
    },
  
    storage: {
      collection_name: "marketplace_store",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "Multi Vendor Store enables easy multi-vendor selling on your platform, allowing Site Members to become vendors without creating new accounts. Vendors can quickly create and sell products through an integrated portal, simplifying the process. The app offers a straightforward commission structure, allowing vendors to earn commissions on sales while site owners benefit from increased revenue. With an intuitive interface, you can customize vendor portals, approve products swiftly, and maintain quality control, creating a collaborative and dynamic marketplace.",
      url: "https://help.marketpushapps.com/en/articles/8715610-how-to-setup-marketplace-store-app",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: true,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://img.youtube.com/vi/K8autEkalQE/maxresdefault.jpg",
          url: "https://www.youtube.com/watch?v=K8autEkalQE",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Marketplace Store",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  