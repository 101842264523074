export default {
    app_info: {
        app_name: "buy_x_get_y_discount",
        app_id: "a9074ccb1-fc42-4dcf-a457-0287c8a31df5", 
        app_title: "Buy X Get Y Discount",
        app_icon: " https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-buy-x-get-y/buyXGetYLogo.png", 
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/11389676-buy-x-get-y", 
        review_url: "https://www.wix.com/app-market/add-review/a9074ccb1-fc42-4dcf-a457-0287c8a31df5", 
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: " Buy X and Get Y Discount App helps drive sales and customer engagement by offering enticing discounts when specific spending thresholds are met. Easily configure discount rules, customise offers, and align the design with your brand. Enhance your customers’ shopping experience and encourage larger purchases with seamless, automated discount functionality.", 
        url: "https://help.marketpushapps.com/en/articles/10398688-how-to-use-buy-x-get-y", 
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to add the app & change the template?",
                    url: "#h_a863a25f52",
                },
                {
                    title: "2. What are the available templates?",
                    url: "#h_6cd334d305",
                },
                {
                    title: "3. Can my customers add a review?",
                    url: "#h_790db58c2a",
                },
                {
                    title: "4. How to modify the content?",
                    url: "#h_74a006b1e6",
                },
                {
                    title: "4. How to add/or remove items",
                    url: "#h_cd9e7a2422",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: false, // TBD
        videos: [{
            title: "How to use Buy X Get Y Discount",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=123456", // TBD
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Buy X Get Y Discount",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
            title: "App setup is too complicated",
            message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
            title: "Subscriptions are too expensive",
            message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
            title: "App has issues",
            message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
            title: "Other",
            message: "🥲 Feedback: I have a problem with --> ",
        },
        ],
    },
};