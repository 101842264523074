
export default {
    app_info: {
      app_name: "bulk_coupon_generator",
      app_id: "bde832d0-2c62-41a0-bcb7-6600f4ecfef8",
      app_title: "Bulk Coupon Generator",

      app_icon:
        "https://static.wixstatic.com/media/33caa7_ff45484512e24c22a53f707733bb9d2b~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/collections/7337726-bulk-coupon-generator",
      review_url:
        "https://www.wix.com/app-market/add-review/bde832d0-2c62-41a0-bcb7-6600f4ecfef8",
    },
  
    storage: {
      collection_name: "bulk_coupon_generator",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "The Bulk Coupon Generator app helps you create unique, single-use coupons for your customers, preventing coupon abuse and protecting your business from losses. Generate bulk coupons for Stores, Bookings, and Events, using either automatically generated codes or your own comma-separated list. The app ensures each coupon is only usable once, eliminating the risk of multiple uses or sharing. Generated coupon sets can be viewed, downloaded in CSV format, and used whenever needed, providing a simple and secure way to manage promotions.",
      url: "https://help.marketpushapps.com/en/articles/10419415-how-to-use-foursquare-reviews",
    },
  
    tutorial_articles: {
      show: true,
      articles_collections: [
        {
          collection_name: "Help Articles",
          articles: [
            {
              title: "Can I add a coupon for a single Product or Service?",
              url: "https://help.marketpushapps.com/en/articles/8690782-can-i-add-a-coupon-for-a-single-product-or-service",
            },
            {
              title: "My Coupon Set generation is not working",
              url: "https://help.marketpushapps.com/en/articles/8695677-my-coupon-set-generation-is-not-working",
            },
            {
              title: "Where can I find coupons created by the app?",
              url: "https://help.marketpushapps.com/en/articles/8714482-bulk-coupon-generator-app-where-can-i-find-coupons-created-by-the-app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Bulk Coupon Generator",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  