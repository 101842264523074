
export default {
    app_info: {
      app_name: "business_hours_open_sign",
      app_id: "5ee9e99e-e0a4-4b16-8ecb-761d2448fa17",
      app_title: "Business Hours Open Sign",
      app_icon:
        "https://static.wixstatic.com/media/f95193_c6224576a52a47cf9271e60738f28d63~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/collections/11401452-business-hours-open-sign",
      review_url:
        "https://www.wix.com/app-market/add-review/5ee9e99e-e0a4-4b16-8ecb-761d2448fa17",
    },
  
    storage: {
      collection_name: "business_hours_open_sign",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "The Business Hours Open Sign app allows businesses to easily display customizable hours, including special closures and holidays, on their website. Key features include real-time updates, dynamic day highlighting, and fast integration for seamless site performance. Users can personalize closed days with custom labels and instantly preview and publish changes. This intuitive tool ensures customers always know when a business is open, reducing confusion and improving customer experience. Perfect for any business needing accurate, easily updated operating hours online.",
      url: "https://help.marketpushapps.com/en/articles/10414595-how-to-use-business-hours-open-sign",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Business Hours Open Sign",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  