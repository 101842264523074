
export default {
    app_info: {
        app_name: "vacation_mode",
        app_id: "11782d88-d2d0-43bc-94c5-c0d68369aa19",
        app_title: "Vacation Mode",
        app_icon: "https://static.wixstatic.com/media/33caa7_bdfff8a804b44916a4cb07be1d7af68c~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/7542769-vacation-mode", 
        review_url: "https://www.wix.com/app-market/add-review/11782d88-d2d0-43bc-94c5-c0d68369aa19",
    },
    storage: {
        collection_name: "vacation_mode",
      },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Vacation Mode allows you to manage your website while on break. Add a customizable banner to inform customers about your vacation or slower business days, and customize checkout with a warning message about potential delays. You can also block the checkout process entirely, preventing unwanted orders during your absence. Unlike other modes, Vacation Mode keeps all pages active for search engine crawlers, preserving your SEO rankings. Relax knowing your site remains accessible and your online presence is intact while you take a stress-free vacation.",
        url: "https://help.marketpushapps.com/en/articles/8713895-how-to-use-vacation-mode",
    },
    

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Help Articles",
            articles: [
                {
                    title: "1. The Popup is not showing on homepage",
                    url: "https://help.marketpushapps.com/en/articles/8863548-the-popup-is-not-showing-on-homepage",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Vacation Mode",
            thumbnail: "https://img.youtube.com/vi/itDUi1wUngw/maxresdefault.jpg", 
            url: "https://www.youtube.com/watch?v=itDUi1wUngw",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Vacation Mode",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};