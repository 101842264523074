export default {
    app_info: {
      app_name: "age_verification_pro",
      app_id: "0ae2a363-4587-4d31-8e52-fc39d2e61fb9",
      app_title: "Age Verification Pro",
      app_icon:
        "https://static.wixstatic.com/media/33caa7_316134ccb9d344af999be0b83f9edc0f~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/9047476-tbd",
      review_url:
        "https://www.wix.com/app-market/add-review/0ae2a363-4587-4d31-8e52-fc39d2e61fb9",
    },
  
    storage: {
      collection_name: "age_verification_pro",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "Age Verification Pro helps you easily add age verification popups to your site with customizable, ready-to-use templates for different business needs. Tailor the process to your brand, ensuring a seamless user experience. The app also includes age warnings at checkout to remind customers to meet legal age requirements, supporting compliance and responsible practices. Whether for e-commerce, content sites, or other businesses, this solution ensures you meet legal obligations with minimal hassle, providing a smooth and compliant age verification process.",
      url: "https://help.marketpushapps.com/en/articles/9047476-tbd",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: true,
      videos: [
        {
          title: "How to use Age Verification Pro",
          thumbnail: "https://img.youtube.com/vi/yDt7SqT9CR0/maxresdefault.jpg",
          url: "https://www.youtube.com/watch?v=yDt7SqT9CR0",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Age Verification Pro",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  