export default {
  app_info: {
    app_name: "mkp-dev-quantity-and-volumes",
    app_id: "9574929e-f0d9-40cc-8f69-e9d3b70b7a7c",
    app_title: "Quantity & Volume Discounts",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/mkp-dev-quantity-and-volumes/Logo/logo.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/10295045-how-to-use-quantity-and-volume-discounts",
    review_url:
      "https://www.wix.com/app-market/add-review/9574929e-f0d9-40cc-8f69-e9d3b70b7a7c",
  },

  storage: {
    collection_name: "mkp-dev-quantity-and-volumes",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Quantity and Volume Discount App drives higher sales by offering tiered discounts based on the quantity or volume of products purchased. Easily configure discount rules and customize the design to fit your website’s style. Enhance customer satisfaction and encourage bulk purchases with this effective sales-boosting tool.",
    url: "https://help.marketpushapps.com/en/articles/10295045-how-to-use-quantity-and-volume-discounts",
  },

  tutorial_articles: {
    show: false,
    articles_collections: [
      {
        collection_name: "How to use Quantity & Volume Discounts",
        articles: [
          {
            title: "1. How to add Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=1.%20How%20to%20Add%20Area%20Chart",
          },
          {
            title: "2. How To Represent Your data in Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=2.%20How%20To%20Represent%20Your%20data.",
          },
          {
            title: "3. How To Change the Style of Your Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=3.%20How%20To%20Change%20the%20Style%20of%20Your%20Chart.",
          },
          {
            title: "4. How do I get additional support?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=How%20do%20I%20get%20additional%20support%3F",
          },
          {
            title: "5. Add a Review for the app",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=Add%20a%20Review%20for%20the%20app",
          },
        ],
      },
      // {
      //   collection_name: "Help Articles",
      //   articles: [
      //     {
      //       title: "How to add colors without a color picker?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_e750f2ba12",
      //     },
      //     {
      //       title: "How to upload my own image?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_3eb4f36ee2",
      //     },
      //     {
      //       title: "How to add HTML content to Popup message?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_9e7bc726e1",
      //     },
      //     {
      //       title:
      //         "How can I Disable/Hide the popup message for a specific hotspot?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_5e7e7e3036",
      //     },
      //   ],
      // },
    ],
  },

  tutorial_videos: {
    show: false,
    videos: [
      {
        title: "How to use image hotspots",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
        url: "https://www.youtube.com/watch?v=123456",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Quantity & Volume Discounts",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
