export default {
    app_info: {
        app_name: "auction_store",
        app_id: "ab6992f6-bc9d-4ab4-8ede-d7d6a4840253",
        app_title: "Auction Store",
        app_icon: "https://static.wixstatic.com/media/0904c3_76bef087a53a4288a2f6bb19366197b9~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/10975826-auction-store",
        review_url: "https://www.wix.com/app-market/add-review/ab6992f6-bc9d-4ab4-8ede-d7d6a4840253",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Auction Store app enables users to host exciting online auctions directly on their websites. It includes a customizable Auction Gallery widget and a product page plugin widget. The app also features a powerful dashboard to manage auctions, track stats, and more for a seamless auction experience.",
        url: "https://help.marketpushapps.com/en/collections/10975826-auction-store",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Getting Started",
            // TBD
            articles: [
                {
                    title: "1. How to add the app & change the template?",
                    url: "#h_a863a25f52",
                },
                {
                    title: "2. What are the available templates?",
                    url: "#h_6cd334d305",
                },
                {
                    title: "3. Can my customers add a review?",
                    url: "#h_790db58c2a",
                },
                {
                    title: "4. How to modify the content?",
                    url: "#h_74a006b1e6",
                },
                {
                    title: "4. How to add/or remove items",
                    url: "#h_cd9e7a2422",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: false, // TBD
        videos: [{
            title: "How to use Auction Store",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=123456", // TBD
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Auction Store",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
            title: "App setup is too complicated",
            message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
            title: "Subscriptions are too expensive",
            message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
            title: "App has issues",
            message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
            title: "Other",
            message: "🥲 Feedback: I have a problem with --> ",
        },
        ],
    },
};