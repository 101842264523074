//

export default {
    app_info: {
        app_name: "yelp_reviews",
        app_id: "04e6ed92-3bb1-45f1-9503-9cdd41c62ad9",
        app_title: "Yelp Reviews",
        app_icon: "https://static.wixstatic.com/media/19e924_b1fec6167c68461f8f0b63d87f979bdb~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_b1fec6167c68461f8f0b63d87f979bdb~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/7329930-yelp-reviews", 
        review_url: "https://www.wix.com/app-market/add-review/04e6ed92-3bb1-45f1-9503-9cdd41c62ad9",
    },

    tutorial_main: {
        show: false,
        title: "📘 Getting started guide",
        description: "Easily display Yelp reviews on your website to enhance credibility and attract more customers. Seamlessly integrate authentic customer feedback with real-time updates, saving you time. Customize review widgets to match your site’s style, choosing from various templates for an engaging display. By highlighting positive Yelp reviews, you build trust, increase engagement, and stand out from competitors. Leverage compelling social proof to boost traffic, enhance conversions, and establish your site as a trusted destination, driving business growth and customer confidence.",
        url: "https://help.marketpushapps.com/en/articles/8702033-how-to-use-yelp-reviews-app",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to use Yelp Reviews App?",
                    url: "https://help.marketpushapps.com/en/articles/8702033-how-to-use-yelp-reviews-app",
                },
                {
                    title: "2. How to change widget position?",
                    url: "https://help.marketpushapps.com/en/articles/8713859-how-to-change-widget-position",
                },
                {
                    title: "3. How to add a link to your Yelp Listing within the widget?",
                    url: "https://help.marketpushapps.com/en/articles/8713889-how-to-add-a-link-to-your-yelp-listing-within-the-widget",
                },
            ],
        },
        {
            collection_name: "Help Articles",
            articles: [
                {
                    title: "I don't have a Yelp Page",
                    url: "https://help.marketpushapps.com/en/articles/8713150-i-don-t-have-a-yelp-page",
                },

                {
                    title: "Is there a way to embed the widget so it doesn't scroll",
                    url: "https://help.marketpushapps.com/en/articles/8713277-is-there-a-way-to-embed-the-widget-so-it-doesn-t-scroll",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Yelp Reviews",
            thumbnail: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/yelp_yt_thumbnail.png", 
            url: "https://www.youtube.com/watch?v=sRMRcQXjUS4",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Yelp Reviews",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};