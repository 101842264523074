export default {
    app_info: {
        app_name: "logo_gallery",
        app_id: "d2043286-f903-4d1d-8f00-eaf98e4021ea",
        app_title: "Logo Gallery",
        app_icon: "https://static.wixstatic.com/media/19e924_5b15730119724c53af38584398f2bde5~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase",
        review_url: "https://www.wix.com/app-market/add-review/d2043286-f903-4d1d-8f00-eaf98e4021ea",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Logo Gallery helps you display your brand logos in a professional, sleek way. Choose from ten layout options, allowing you to showcase your logos attractively. You can use multiple widgets and display any type of image, including vector art and more. Simple to use, with customizable elements in each template, ensuring your logos are presented exactly how you want them.",
        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
                collection_name: "Getting Started",
                // TBD
                articles: [
                    {
                        title: "1. How to add the app & change the template?",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_a863a25f52",
                    },
                    {
                        title: "2. What are the available templates?",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_6cd334d305",
                    },
                    {
                        title: "3. Can my customers add a review?",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_790db58c2a",
                    },
                    {
                        title: "4. How to modify the content?",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_74a006b1e6",
                    },
                    {
                        title: "4. How to add/or remove items",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase#h_cd9e7a2422",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: false, // TBD
        videos: [{
            title: "How to use Logo Gallery",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=123456", // TBD
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Logo Gallery",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};