export default {
    app_info: {
        app_name: "email_marketing_with_gmail",
        app_id: "77b79d20-a4a1-48e1-99af-150bdafdb65e",
        app_title: "Email Marketing with Gmail",
        app_icon: "https://static.wixstatic.com/media/c7fe21_96a8ba7adfd74c65a840cbffa8da993b~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/9970043-email-marketing-with-gmail-email-marketing-marketpush",
        review_url: "https://www.wix.com/app-market/add-review/77b79d20-a4a1-48e1-99af-150bdafdb65e",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Effortlessly send professional email marketing campaigns directly from your Gmail or Google Business email. With seamless Gmail integration, automatic contact syncing, and expert moderation, our app ensures your messages land in inboxes, not spam folders. Stay connected, grow your audience, and boost engagement from your familiar Gmail account.",
        url: "https://help.marketpushapps.com/en/collections/9970043-email-marketing-with-gmail-email-marketing-marketpush",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Getting Started",
            // TBD
            articles: [
                {
                    title: "1. How to add the app & change the template?",
                    url: "#h_a863a25f52",
                },
                {
                    title: "2. What are the available templates?",
                    url: "#h_6cd334d305",
                },
                {
                    title: "3. Can my customers add a review?",
                    url: "#h_790db58c2a",
                },
                {
                    title: "4. How to modify the content?",
                    url: "#h_74a006b1e6",
                },
                {
                    title: "4. How to add/or remove items",
                    url: "#h_cd9e7a2422",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: false, // TBD
        videos: [{
            title: "How to use Email Marketing with Gmail",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=123456", // TBD
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Email Marketing with Gmail",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
            title: "App setup is too complicated",
            message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
            title: "Subscriptions are too expensive",
            message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
            title: "App has issues",
            message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
            title: "Other",
            message: "🥲 Feedback: I have a problem with --> ",
        },
        ],
    },
};