export default {
  app_info: {
    app_name: "mkp-dev-bookings-products-cross-sell",
    app_id: "245d0425-8d2f-491f-9812-d80ccc445a98",
    app_title: "Bookings Products Cross Sell",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/mkp-dev-bookings-products-cross-sell/logo/logo.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/10295046-how-to-use-bookings-products-cross-sell",
    review_url:
      "https://www.wix.com/app-market/add-review/245d0425-8d2f-491f-9812-d80ccc445a98",
  },

  storage: {
    collection_name: "mkp-dev-bookings-products-cross-sell",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Booking Products Cross-Sell App helps boost sales by showcasing relevant products or services alongside bookings. Easily customize recommendations and match the design to your website. Enhance your customer experience and drive conversions effortlessly.",
    url: "https://help.marketpushapps.com/en/articles/10295046-how-to-use-bookings-products-cross-sell",
  },

  tutorial_articles: {
    show: false,
    articles_collections: [
      {
        collection_name: "How to use Bookings Products Cross Sell",
        articles: [
          {
            title: "1. How to add Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=1.%20How%20to%20Add%20Area%20Chart",
          },
          {
            title: "2. How To Represent Your data in Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=2.%20How%20To%20Represent%20Your%20data.",
          },
          {
            title: "3. How To Change the Style of Your Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=3.%20How%20To%20Change%20the%20Style%20of%20Your%20Chart.",
          },
          {
            title: "4. How do I get additional support?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=How%20do%20I%20get%20additional%20support%3F",
          },
          {
            title: "5. Add a Review for the app",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=Add%20a%20Review%20for%20the%20app",
          },
        ],
      },
      // {
      //   collection_name: "Help Articles",
      //   articles: [
      //     {
      //       title: "How to add colors without a color picker?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_e750f2ba12",
      //     },
      //     {
      //       title: "How to upload my own image?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_3eb4f36ee2",
      //     },
      //     {
      //       title: "How to add HTML content to Popup message?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_9e7bc726e1",
      //     },
      //     {
      //       title:
      //         "How can I Disable/Hide the popup message for a specific hotspot?",
      //       url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_5e7e7e3036",
      //     },
      //   ],
      // },
    ],
  },

  tutorial_videos: {
    show: false,
    videos: [
      {
        title: "How to use image hotspots",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
        url: "https://www.youtube.com/watch?v=123456",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Bookings Products Cross Sell",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
