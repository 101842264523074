
export default {
    app_info: {
      app_name: "foursquare_reviews",
      app_id: "3e6dd1e6-fdfa-45fb-8cbe-43028fd0419c",
      app_title: "Foursquare Reviews",

      app_icon:
        "https://static.wixstatic.com/media/19e924_e0341ae0da204bfd9636b34befb32ffa~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/collections/11406222-foursquare-reviews",
      review_url:
        "https://www.wix.com/app-market/add-review/3e6dd1e6-fdfa-45fb-8cbe-43028fd0419c",
    },
  
    storage: {
      collection_name: "foursquare_reviews",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "The Foursquare Reviews app allows you to easily showcase authentic user reviews on your website, building trust and attracting more visitors. Seamlessly integrate and display reviews with automatic syncing, eliminating manual updates. Customize review widgets to match your site’s design, choosing from various templates for a visually appealing display. Highlight positive reviews to enhance credibility, increase engagement, and stand out from competitors. Leverage the power of social proof to drive traffic, boost conversions, and strengthen your online reputation.",
      url: "https://help.marketpushapps.com/en/articles/10419415-how-to-use-foursquare-reviews",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Foursquare Reviews",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  